import * as Blockly from 'blockly';
import * as python from 'blockly/python'
import { BlockLogo } from 'src/app/shared/enums/SequenceKeys.enum';
import { BlockColors } from '../blockly-theme';

export const DEVICE_BLOCKS = [
  {
    "type": "sequence_device",
    "message0": "%1 Sequence %2 %3 run %4 scope %5 %6",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "field_input",
        "name": "markername"
      },
      {
        "type": "field_dropdown",
        "name": "RUN_STATUS",
        "options": [
          [
            "disable",
            "DISABLE"
          ],
          [
            "enable",
            "ENABLE"
          ]
        ]
      },
      {
        "type": "field_dropdown",
        "name": "Occurance",
        "options": [
          [
            "every minute",
            "EVERY_MINUTE"
          ],
          [
            "every hour",
            "HOURLY"
          ],
          [
            "once a day",
            "DAILY"
          ],
          [
            "once a week",
            "WEEKLY"
          ],
          [
            "once a month",
            "EVERY_MONTH"
          ],
        ]
      },
      {
        "type": "field_dropdown",
        "name": "scope",
        "options": [
          [
            "Local Download",
            "DEFAULT_SCOPE"
          ]
        ]
      },
      {
        "type": "field_input",
        "name": "sequenceId"
      }
    ],
    "message1": "%1",
    "args1": [
      { "type": "input_statement", "name": "CODE" }
    ],
    "inputsInline": true,
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "tooltip": "This block does the job of naming a sequence and contributes to the canvas navigation ",
    "helpUrl": ""
  },
  {
    "type": "sequencer",
    "message0": "%1 sequencer %2 \n why %3 \n what %4 %5",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "field_input",
        "name": "seqFunctionName"
      },
      {
        "type": "field_input",
        "name": "why"
      },
      {
        "type": "field_input",
        "name": "what"
      },
      {
        "type": "field_input",
        "name": "functionId"
      },
    ],
    "message1": "%1",
    "args1": [
      { "type": "input_statement", "name": "CODE" }
    ],
    "inputsInline": true,
    "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
    "tooltip": "This block does the job of naming a sequence and contributes to the canvas navigation ",
    "helpUrl": ""
  },
  {
  "type": "print_string",
  "message0": "%1 String To Print %2",
  "args0": [
    {
      "type": "field_image",
      "src": BlockLogo.IMAGE,
      "width": BlockLogo.WIDTH,
      "height": BlockLogo.HEIGHT,
      "alt": BlockLogo.ALT_TEXT
    },
    {
      "type": "input_value",
      "name": "string_to_print",
      "check": [
        "String",
        "Number"
      ]
    }
  ],
  "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "previousStatement": null,
  "nextStatement": null,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "conn_mod_set_analog_output",
  "message0": "%1 Set Analog Output %2 Aout number: %3 Aout value: %4",
  "args0": [
    {
      "type": "field_image",
      "src": BlockLogo.IMAGE,
      "width": BlockLogo.WIDTH,
      "height": BlockLogo.HEIGHT,
      "alt": BlockLogo.ALT_TEXT
    },
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "aout_number",
      "check": "Number",
      "align": "RIGHT"
    },
    {
      "type": "input_value",
      "name": "aout_value",
      "check": "Number",
      "align": "RIGHT"
    }
  ],
  "inputsInline": true,
  "previousStatement": null,
  "nextStatement": null,
  "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "conn_mod_set_relay_output",
  "message0": "%1 Set Relay Output %2 Relay number: %3 Relay state: %4",
  "args0": [
    {
      "type": "field_image",
      "src": BlockLogo.IMAGE,
      "width": BlockLogo.WIDTH,
      "height": BlockLogo.HEIGHT,
      "alt": BlockLogo.ALT_TEXT
    },
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "relay_number",
      "check": "Number",
      "align": "RIGHT"
    },
    {
      "type": "input_value",
      "name": "relay_state",
      "check": "Number",
      "align": "RIGHT"
    }
  ],
  "inputsInline": true,
  "previousStatement": null,
  "nextStatement": null,
  "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "conn_mod_get_universal_input_value",
  "message0": "%1 Get Universal Input Value %2 Uin number: %3",
  "args0": [
    {
      "type": "field_image",
      "src": BlockLogo.IMAGE,
      "width": BlockLogo.WIDTH,
      "height": BlockLogo.HEIGHT,
      "alt": BlockLogo.ALT_TEXT
    },
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "uin_number",
      "check": "Number",
      "align": "RIGHT"
    }
  ],
  "inputsInline": true,
  "output": "Number",
  "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "conn_mod_get_universal_input_mode",
  "message0": "%1 Get Universal Input Mode %2 Number: %3",
  "args0": [
    {
      "type": "field_image",
      "src": BlockLogo.IMAGE,
      "width": BlockLogo.WIDTH,
      "height": BlockLogo.HEIGHT,
      "alt": BlockLogo.ALT_TEXT
    },
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "uin_number",
      "check": "Number"
    }
  ],
  "inputsInline": true,
  "output": "String",
  "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "conn_mod_relay_output_number",
  "message0": "%1 Relay output number: %2",
  "args0": [
    {
      "type": "field_image",
      "src": BlockLogo.IMAGE,
      "width": BlockLogo.WIDTH,
      "height": BlockLogo.HEIGHT,
      "alt": BlockLogo.ALT_TEXT
    },
    {
      "type": "field_dropdown",
      "name": "relay_output_number",
      "options": [
        [
          "1",
          "0"
        ],
        [
          "2",
          "1"
        ],
        [
          "3",
          "2"
        ],
        [
          "4",
          "3"
        ],
        [
          "5",
          "4"
        ],
        [
          "6",
          "5"
        ],
        [
          "7",
          "6"
        ],
        [
          "8",
          "7"
        ]
      ]
    }
  ],
  "output": "Number",
  "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "conn_mod_relay_output_state",
  "message0": "%1 Set relay output state: %2",
  "args0": [
    {
      "type": "field_image",
      "src": BlockLogo.IMAGE,
      "width": BlockLogo.WIDTH,
      "height": BlockLogo.HEIGHT,
      "alt": BlockLogo.ALT_TEXT
    },
    {
      "type": "field_dropdown",
      "name": "relay_output_state",
      "options": [
        [
          "Off",
          "0"
        ],
        [
          "On",
          "1"
        ]
      ]
    }
  ],
  "output": "Number",
  "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "conn_mod_analog_output_number",
  "message0": "%1 Analog output number: %2",
  "args0": [
    {
      "type": "field_image",
      "src": BlockLogo.IMAGE,
      "width": BlockLogo.WIDTH,
      "height": BlockLogo.HEIGHT,
      "alt": BlockLogo.ALT_TEXT
    },
    {
      "type": "field_dropdown",
      "name": "analog_output_number",
      "options": [
        [
          "1",
          "0"
        ],
        [
          "2",
          "1"
        ],
        [
          "3",
          "2"
        ],
        [
          "4",
          "3"
        ]
      ]
    }
  ],
  "output": "Number",
  "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "conn_mod_analog_output_value",
  "message0": "%1 Set aout value: %2",
  "args0": [
    {
      "type": "field_image",
      "src": BlockLogo.IMAGE,
      "width": BlockLogo.WIDTH,
      "height": BlockLogo.HEIGHT,
      "alt": BlockLogo.ALT_TEXT
    },
    {
      "type": "field_number",
      "name": "analog_output_value",
      "value": 0,
      "min": 0,
      "max": 10,
      "precision": 0.1
    }
  ],
  "output": "Number",
  "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "conn_mod_sensor_number",
  "message0": "%1 Sensor number: %2",
  "args0": [
    {
      "type": "field_image",
      "src": BlockLogo.IMAGE,
      "width": BlockLogo.WIDTH,
      "height": BlockLogo.HEIGHT,
      "alt": BlockLogo.ALT_TEXT
    },
    {
      "type": "field_dropdown",
      "name": "sensor_number",
      "options": [
        [
          "1",
          "0"
        ],
        [
          "2",
          "1"
        ],
        [
          "3",
          "2"
        ],
        [
          "4",
          "3"
        ]
      ]
    }
  ],
  "output": "Number",
  "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "conn_mod_get_sensor_bus_value",
  "message0": "%1 Get Sensor Bus Value %2 Sensor number: %3 Sensor type: %4",
  "args0": [
    {
      "type": "field_image",
      "src": BlockLogo.IMAGE,
      "width": BlockLogo.WIDTH,
      "height": BlockLogo.HEIGHT,
      "alt": BlockLogo.ALT_TEXT
    },
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "sensor_number",
      "check": "Number",
      "align": "RIGHT"
    },
    {
      "type": "input_value",
      "name": "sensor_type",
      "check": "Number",
      "align": "RIGHT"
    }
  ],
  "inputsInline": true,
  "output": [
    "Number",
    "Boolean"
  ],
  "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "conn_mod_universal_input_number",
  "message0": "%1 Universal input number: %2",
  "args0": [
    {
      "type": "field_image",
      "src": BlockLogo.IMAGE,
      "width": BlockLogo.WIDTH,
      "height": BlockLogo.HEIGHT,
      "alt": BlockLogo.ALT_TEXT
    },
    {
      "type": "field_dropdown",
      "name": "channel_number",
      "options": [
        [
          "1",
          "0"
        ],
        [
          "2",
          "1"
        ],
        [
          "3",
          "2"
        ],
        [
          "4",
          "3"
        ],
        [
          "5",
          "4"
        ],
        [
          "6",
          "5"
        ],
        [
          "7",
          "6"
        ],
        [
          "8",
          "7"
        ]
      ]
    }
  ],
  "output": "Number",
  "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "conn_mod_universal_input_mode",
  "message0": "%1 Universal input mode: %2",
  "args0": [
    {
      "type": "field_image",
      "src": BlockLogo.IMAGE,
      "width": BlockLogo.WIDTH,
      "height": BlockLogo.HEIGHT,
      "alt": BlockLogo.ALT_TEXT
    },
    {
      "type": "field_dropdown",
      "name": "uin_mode",
      "options": [
        [
          "Analog input",
          "0"
        ],
        [
          "Thermistor input",
          "1"
        ]
      ]
    }
  ],
  "output": "Number",
  "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "conn_mod_set_universal_input_mode",
  "message0": "%1 Set Universal Input Mode %2 Universal input number %3 Universal input mode %4",
  "args0": [
    {
      "type": "field_image",
      "src": BlockLogo.IMAGE,
      "width": BlockLogo.WIDTH,
      "height": BlockLogo.HEIGHT,
      "alt": BlockLogo.ALT_TEXT
    },
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "uin_number",
      "check": "Number",
      "align": "RIGHT"
    },
    {
      "type": "input_value",
      "name": "uin_mode",
      "check": "Number",
      "align": "RIGHT"
    }
  ],
  "inputsInline": true,
  "previousStatement": null,
  "nextStatement": null,
  "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "sequence_run_continuous",
  "message0": "%1 Run Sequence Continuously %2 every %3 seconds %4 %5",
  "args0": [
    {
      "type": "field_image",
      "src": BlockLogo.IMAGE,
      "width": BlockLogo.WIDTH,
      "height": BlockLogo.HEIGHT,
      "alt": BlockLogo.ALT_TEXT
    },
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "run_rate",
      "check": "Number"
    },
    {
      "type": "input_dummy"
    },
    {
      "type": "input_statement",
      "name": "sequence"
    }
  ],
  "inputsInline": true,
  "previousStatement": null,
  "nextStatement": null,
   "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "sequence_run_startup",
  "message0": "%1 Run Sequence On Startup %2 %3",
  "args0": [
    {
      "type": "field_image",
      "src": BlockLogo.IMAGE,
      "width": BlockLogo.WIDTH,
      "height": BlockLogo.HEIGHT,
      "alt": BlockLogo.ALT_TEXT
    },
    {
      "type": "input_dummy"
    },
    {
      "type": "input_statement",
      "name": "sequence"
    }
  ],
  "inputsInline": true,
   "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "conn_mod_write_universal_input_modbus_register",
  "message0": "%1 Write universal input %2 %3 modbus register %4 value %5",
  "args0": [
    {
      "type": "field_image",
      "src": BlockLogo.IMAGE,
      "width": BlockLogo.WIDTH,
      "height": BlockLogo.HEIGHT,
      "alt": BlockLogo.ALT_TEXT
    },
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "uin_number",
      "check": "Number",
      "align": "RIGHT"
    },
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "uin_value",
      "check": "Number",
      "align": "RIGHT"
    }
  ],
  "inputsInline": true,
  "previousStatement": null,
  "nextStatement": null,
  "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "universal_input_standard_sensor_types",
  "message0": "%1 Standard Sensor Type %2",
  "args0": [
    {
      "type": "field_image",
      "src": BlockLogo.IMAGE,
      "width": BlockLogo.WIDTH,
      "height": BlockLogo.HEIGHT,
      "alt": BlockLogo.ALT_TEXT
    },
    {
      "type": "field_dropdown",
      "name": "sensor_type",
      "options": [
        [
          "None",
          "0"
        ],
        [
          "Voltage",
          "1"
        ],
        [
          "Resistance",
          "2"
        ],
        [
          "Building Static Pressure (0-1 in/wc)",
          "3"
        ],
        [
          "Building Static Pressure (0-2 in/wc)",
          "4"
        ],
        [
          "Building Static Pressure (0-10 in/wc)",
          "5"
        ],
        [
          "Chilled Water Inlet Temp",
          "6"
        ],
        [
          "Chilled Water Outlet Temp",
          "7"
        ],
        [
          "Cooling Valve Position Feedback",
          "8"
        ],
        [
          "Discharge Air Damper Feedback",
          "9"
        ],
        [
          "Discharge Air Flow Sensor (0-10 m/s)",
          "10"
        ],
        [
          "Discharge Air Flow Sensor (0-20 m/s)",
          "11"
        ],
        [
          "Discharge Air Humidity",
          "12"
        ],
        [
          "Discharge Air PM2.5",
          "13"
        ],
        [
          "Discharge Air Temperature",
          "14"
        ],
        [
          "Discharge Fan VFD Feedback",
          "15"
        ],
        [
          "Duct Static Pressure Sensor 1 (0-1 in/wc)",
          "16"
        ],
        [
          "Duct Static Pressure Sensor 1 (0-2 in/wc)",
          "17"
        ],
        [
          "Duct Static Pressure Sensor 1 (0-10 in/wc)",
          "18"
        ],
        [
          "Duct Static Pressure Sensor 2 (0-1 in/wc)",
          "19"
        ],
        [
          "Duct Static Pressure Sensor 2 (0-2 in/wc)",
          "20"
        ],
        [
          "Duct Static Pressure Sensor 2 (0-10 in/wc)",
          "21"
        ],
        [
          "Duct Static Pressure Sensor 3 (0-1 in/wc)",
          "22"
        ],
        [
          "Duct Static Pressure Sensor 3 (0-2 in/wc)",
          "23"
        ],
        [
          "Duct Static Pressure Sensor 3 (0-10 in/wc)",
          "24"
        ],
        [
          "Heating Valve Position Feedback",
          "25"
        ],
        [
          "Minimum Outside Air Damper Feedback",
          "26"
        ],
        [
          "Mixed Air Damper Feedback",
          "27"
        ],
        [
          "Mixed Air Temperature",
          "28"
        ],
        [
          "Outside Air Damper Feedback",
          "29"
        ],
        [
          "Outside Airflow Sensor (0-10)",
          "30"
        ],
        [
          "Outside Airflow Sensor (0-20)",
          "31"
        ],
        [
          "Outside Air Humidity",
          "32"
        ],
        [
          "Outside Air Temperature",
          "33"
        ],
        [
          "Return Air CO2 Sensor",
          "34"
        ],
        [
          "Mixed Air CO2 Sensor",
          "35"
        ],
        [
          "Return Air Damper Feedback",
          "36"
        ],
        [
          "Return Air Humidity",
          "37"
        ],
        [
          "Return Air Temperature",
          "38"
        ],
        [
          "Space Temperature",
          "39"
        ],
        [
          "Mixed Air Humidity",
          "40"
        ],
        [
          "Current TX (0-10 Amp)",
          "41"
        ],
        [
          "Current TX (0-20 Amp)",
          "42"
        ],
        [
          "Current TX (0-30 Amp)",
          "43"
        ],
        [
          "Current TX (0-50 Amp)",
          "44"
        ],
        [
          "Current TX (0-60 Amp)",
          "45"
        ],
        [
          "Current TX (0-100 Amp)",
          "46"
        ],
        [
          "Current TX (0-120 Amp)",
          "47"
        ],
        [
          "Current TX (0-150 Amp)",
          "48"
        ],
        [
          "Current TX (0-200 Amp)",
          "49"
        ],
        [
          "Exhaust Fan VFD Feedback",
          "50"
        ],
        [
          "Outside Fan VFD Feedback",
          "51"
        ],
        [
          "Return Fan VFD Feedback",
          "52"
        ],
        [
          "Discharge Fan Auto Manual Status",
          "53"
        ],
        [
          "Discharge Fan Run Status",
          "54"
        ],
        [
          "Discharge Fan Trip Status",
          "55"
        ],
        [
          "Exhaust Fan Auto Manual Status",
          "56"
        ],
        [
          "UV Auto Manual Status",
          "57"
        ],
        [
          "Exhaust Fan Run Status",
          "58"
        ],
        [
          "Exhaust Fan Trip Status",
          "59"
        ],
        [
          "Filter Status 1 N/O",
          "60"
        ],
        [
          "Filter Status 1 N/C",
          "61"
        ],
        [
          "Filter Status 2 N/O",
          "62"
        ],
        [
          "Filter Status 2 N/C",
          "63"
        ],
        [
          "Fire Alarm Status",
          "64"
        ],
        [
          "Fire Damper Status 1",
          "65"
        ],
        [
          "Fire Damper Status 2",
          "66"
        ],
        [
          "Fire Damper Status 3",
          "67"
        ],
        [
          "Fire Damper Status 4",
          "68"
        ],
        [
          "Fire Damper Status 5",
          "69"
        ],
        [
          "Fire Damper Status 6",
          "70"
        ],
        [
          "Fire Damper Status 7",
          "71"
        ],
        [
          "Fire Damper Status 8",
          "72"
        ],
        [
          "High Differential Pressure Switch",
          "73"
        ],
        [
          "Low Differential Pressure Switch",
          "74"
        ],
        [
          "Outside Fan Run Status",
          "75"
        ],
        [
          "Outside Fan Trip Status",
          "76"
        ],
        [
          "Outside Fan Auto Manual Status",
          "77"
        ],
        [
          "Return Fan Run Status",
          "78"
        ],
        [
          "Return Fan Trip Status",
          "79"
        ],
        [
          "Return Fan Auto Manual Status",
          "80"
        ],
        [
          "UV Run Status",
          "81"
        ],
        [
          "UV Trip Status",
          "82"
        ],
        [
          "Condensate Status N/O",
          "83"
        ],
        [
          "Condensate Status N/C",
          "84"
        ],
        [
          "Supply Air Temperature 1",
          "85"
        ],
        [
          "Supply Air Humidity 1",
          "86"
        ],
        [
          "Supply Air Temperature 2",
          "87"
        ],
        [
          "Supply Air Humidity 2",
          "88"
        ],
        [
          "Supply Air Temperature 3",
          "89"
        ],
        [
          "Supply Air Humidity 3",
          "90"
        ],
        [
          "Emergency Shutoff N/O",
          "91"
        ],
        [
          "Emergency Shutoff N/C",
          "92"
        ],
        [
          "Generic Alarm N/O",
          "93"
        ],
        [
          "Generic Alarm N/C",
          "94"
        ],
        [
          "Door/Window Sensor N/C",
          "95"
        ],
        [
          "Door/Window Sensor",
          "96"
        ],
        [
          "Door/Window Sensor N/C Title 24",
          "97"
        ],
        [
          "Door/Window Sensor Title 24",
          "98"
        ]
      ]
    }
  ],
  "output": "Number",
  "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "universal_input_convert_value_from_standard_sensor_type",
  "message0": "%1 Convert universal input value %2 %3 from sensor type %4",
  "args0": [
    {
      "type": "field_image",
      "src": BlockLogo.IMAGE,
      "width": BlockLogo.WIDTH,
      "height": BlockLogo.HEIGHT,
      "alt": BlockLogo.ALT_TEXT
    },
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "uin_value",
      "check": "Number"
    },
    {
      "type": "input_value",
      "name": "sensor_type_index",
      "check": "Number"
    }
  ],
  "inputsInline": true,
  "output": "Number",
  "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "conn_mod_read_analog_output_modbus_register",
  "message0": "%1 Read analog output %2 %3 modbus register",
  "args0": [
    {
      "type": "field_image",
      "src": BlockLogo.IMAGE,
      "width": BlockLogo.WIDTH,
      "height": BlockLogo.HEIGHT,
      "alt": BlockLogo.ALT_TEXT
    },
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "aout_number",
      "check": "Number",
      "align": "RIGHT"
    }
  ],
  "inputsInline": true,
  "output": "Number",
  "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "conn_mod_read_relay_output_modbus_register",
  "message0": "%1 Read relay output %2 %3 modbus register",
  "args0": [
    {
      "type": "field_image",
      "src": BlockLogo.IMAGE,
      "width": BlockLogo.WIDTH,
      "height": BlockLogo.HEIGHT,
      "alt": BlockLogo.ALT_TEXT
    },
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "relay_number",
      "check": "Number",
      "align": "RIGHT"
    }
  ],
  "inputsInline": true,
  "output": "Number",
  "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "conn_mod_read_universal_input_mapping_modbus_register",
  "message0": "%1 Read universal input number %2 %3 mapping modbus register",
  "args0": [
    {
      "type": "field_image",
      "src": BlockLogo.IMAGE,
      "width": BlockLogo.WIDTH,
      "height": BlockLogo.HEIGHT,
      "alt": BlockLogo.ALT_TEXT
    },
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "uin_number",
      "check": "Number",
      "align": "RIGHT"
    }
  ],
  "inputsInline": true,
  "output": "Number",
  "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "universal_input_get_mode_from_standard_sensor_type",
  "message0": "%1 Get universal input mode %2 from standard sensor type %3",
  "args0": [
    {
      "type": "field_image",
      "src": BlockLogo.IMAGE,
      "width": BlockLogo.WIDTH,
      "height": BlockLogo.HEIGHT,
      "alt": BlockLogo.ALT_TEXT
    },
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "sensor_type_index",
      "check": "Number"
    }
  ],
  "inputsInline": true,
  "output": "Number",
  "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "conn_mod_configure_home_screen_input",
  "message0": "%1 Configure Home Screen Input %2 Input Number %3 Input Name %4 Input Units %5 Input Value %6",
  "args0": [
    {
      "type": "field_image",
      "src": BlockLogo.IMAGE,
      "width": BlockLogo.WIDTH,
      "height": BlockLogo.HEIGHT,
      "alt": BlockLogo.ALT_TEXT
    },
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "uin_number",
      "check": "Number"
    },
    {
      "type": "input_value",
      "name": "input_name",
      "check": "String"
    },
    {
      "type": "input_value",
      "name": "input_units",
      "check": "String"
    },
    {
      "type": "input_value",
      "name": "input_val",
      "check": [
        "Number",
        "String"
      ]
    }
  ],
  "inputsInline": true,
  "previousStatement": null,
  "nextStatement": null,
  "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "conn_mod_update_home_screen_input",
  "message0": "%1 Update Home Screen Input %2 Input Number %3 Input Value %4",
  "args0": [
    {
      "type": "field_image",
      "src": BlockLogo.IMAGE,
      "width": BlockLogo.WIDTH,
      "height": BlockLogo.HEIGHT,
      "alt": BlockLogo.ALT_TEXT
    },
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "uin_number",
      "check": "Number"
    },
    {
      "type": "input_value",
      "name": "input_val",
      "check": [
        "Number",
        "String"
      ]
    }
  ],
  "inputsInline": true,
  "previousStatement": null,
  "nextStatement": null,
  "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "universal_input_get_name_from_standard_sensor_type",
  "message0": "%1 Get name %2 from standard sensor type %3",
  "args0": [
    {
      "type": "field_image",
      "src": BlockLogo.IMAGE,
      "width": BlockLogo.WIDTH,
      "height": BlockLogo.HEIGHT,
      "alt": BlockLogo.ALT_TEXT
    },
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "sensor_type_index",
      "check": "Number"
    }
  ],
  "inputsInline": true,
  "output": "String",
  "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "universal_input_get_units_from_standard_sensor_type",
  "message0": "%1 Get units %2 from standard sensor type %3",
  "args0": [
    {
      "type": "field_image",
      "src": BlockLogo.IMAGE,
      "width": BlockLogo.WIDTH,
      "height": BlockLogo.HEIGHT,
      "alt": BlockLogo.ALT_TEXT
    },
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "sensor_type_index",
      "check": "Number"
    }
  ],
  "inputsInline": true,
  "output": "String",
  "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "sensor_bus_sensor_type",
  "message0": "%1 Sensor bus sensor type: %2",
  "args0": [
    {
      "type": "field_image",
      "src": BlockLogo.IMAGE,
      "width": BlockLogo.WIDTH,
      "height": BlockLogo.HEIGHT,
      "alt": BlockLogo.ALT_TEXT
    },
    {
      "type": "field_dropdown",
      "name": "sensor_type",
      "options": [
        [
          "Temperature",
          "0"
        ],
        [
          "Humidity",
          "1"
        ],
        [
          "Pressure",
          "4"
        ],
        [
          "CO2",
          "2"
        ],
        [
          "Occupancy",
          "3"
        ]
      ]
    }
  ],
  "output": "Number",
  "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "sequence_enter",
  "message0": "%1 Enter Sequence",
  "args0": [{
    "type": "field_image",
    "src": BlockLogo.IMAGE,
    "width": BlockLogo.WIDTH,
    "height": BlockLogo.HEIGHT,
    "alt": BlockLogo.ALT_TEXT
  }],
  "inputsInline": true,
  "previousStatement": null,
  "nextStatement": null,
   "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "sequence_exit",
  "message0": "%1 Exit Sequence",
  "args0": [{
    "type": "field_image",
    "src": BlockLogo.IMAGE,
    "width": BlockLogo.WIDTH,
    "height": BlockLogo.HEIGHT,
    "alt": BlockLogo.ALT_TEXT
  }],
  "inputsInline": true,
  "previousStatement": null,
  "nextStatement": null,
   "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "sequence_delay_milliseconds",
  "message0": "%1 Delay sequence %2 %3 milliseconds",
  "args0": [
    {
      "type": "field_image",
      "src": BlockLogo.IMAGE,
      "width": BlockLogo.WIDTH,
      "height": BlockLogo.HEIGHT,
      "alt": BlockLogo.ALT_TEXT
    },
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "delay_ms",
      "check": "Number"
    }
  ],
  "inputsInline": true,
  "previousStatement": null,
  "nextStatement": null,
   "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "pi_controller_process",
  "message0": "%1 PI Controller Process With: %2 Controller Number %3 Process Variable %4 Setpoint %5 Proportional Gain %6 Integral Gain %7 Error Range %8 Integration Time (s) %9 Sample Time (ms) %10",
  "args0": [
    {
      "type": "field_image",
      "src": BlockLogo.IMAGE,
      "width": BlockLogo.WIDTH,
      "height": BlockLogo.HEIGHT,
      "alt": BlockLogo.ALT_TEXT
    },
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "controller_number",
      "check": "Number",
      "align": "RIGHT"
    },
    {
      "type": "input_value",
      "name": "process_variable",
      "check": "Number",
      "align": "RIGHT"
    },
    {
      "type": "input_value",
      "name": "set_point",
      "check": "Number",
      "align": "RIGHT"
    },
    {
      "type": "input_value",
      "name": "proportional_gain",
      "check": "Number",
      "align": "RIGHT"
    },
    {
      "type": "input_value",
      "name": "integral_gain",
      "check": "Number",
      "align": "RIGHT"
    },
    {
      "type": "input_value",
      "name": "error_range",
      "check": "Number",
      "align": "RIGHT"
    },
    {
      "type": "input_value",
      "name": "integration_time",
      "check": "Number",
      "align": "RIGHT"
    },
    {
      "type": "input_value",
      "name": "sample_time",
      "check": "Number",
      "align": "RIGHT"
    }
  ],
  "inputsInline": false,
  "output": "Number",
  "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "pi_controller_number",
  "message0": "%1 PI Controller Number: %2",
  "args0": [
    {
      "type": "field_image",
      "src": BlockLogo.IMAGE,
      "width": BlockLogo.WIDTH,
      "height": BlockLogo.HEIGHT,
      "alt": BlockLogo.ALT_TEXT
    },
    {
      "type": "field_dropdown",
      "name": "pi_controller_number",
      "options": [
        [
          "1",
          "0"
        ],
        [
          "2",
          "1"
        ],
        [
          "3",
          "2"
        ],
        [
          "4",
          "3"
        ],
        [
          "5",
          "4"
        ],
        [
          "6",
          "5"
        ],
        [
          "7",
          "6"
        ],
        [
          "8",
          "7"
        ]
      ]
    }
  ],
  "output": "Number",
  "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "modbus_register_type",
  "message0": "%1 Modbus register type %2",
  "args0": [
    {
      "type": "field_image",
      "src": BlockLogo.IMAGE,
      "width": BlockLogo.WIDTH,
      "height": BlockLogo.HEIGHT,
      "alt": BlockLogo.ALT_TEXT
    },
    {
      "type": "field_dropdown",
      "name": "register_type",
      "options": [
        [
          "Input",
          "0"
        ],
        [
          "Holding",
          "1"
        ]
      ]
    }
  ],
  "output": "modbus_register_type",
  "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "conn_mod_configure_custom_modbus_register",
  "message0": "%1 Configure custom modbus register: %2 Type %3 Address %4",
  "args0": [
    {
      "type": "field_image",
      "src": BlockLogo.IMAGE,
      "width": BlockLogo.WIDTH,
      "height": BlockLogo.HEIGHT,
      "alt": BlockLogo.ALT_TEXT
    },
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "register_type",
      "check": "modbus_register_type",
      "align": "RIGHT"
    },
    {
      "type": "input_value",
      "name": "register_address",
      "check": "modbus_register_address",
      "align": "RIGHT"
    }
  ],
  "inputsInline": false,
  "previousStatement": null,
  "nextStatement": null,
  "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "conn_mod_read_modbus_register",
  "message0": "%1 Read modbus register: %2 Type %3 Address %4",
  "args0": [
    {
      "type": "field_image",
      "src": BlockLogo.IMAGE,
      "width": BlockLogo.WIDTH,
      "height": BlockLogo.HEIGHT,
      "alt": BlockLogo.ALT_TEXT
    },
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "register_type",
      "check": "modbus_register_type",
      "align": "RIGHT"
    },
    {
      "type": "input_value",
      "name": "register_address",
      "check": "modbus_register_address",
      "align": "RIGHT"
    }
  ],
  "inputsInline": false,
  "output": null,
  "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "conn_mod_write_modbus_register",
  "message0": "%1 Write modbus register: %2 Type %3 Address %4 Value %5",
  "args0": [
    {
      "type": "field_image",
      "src": BlockLogo.IMAGE,
      "width": BlockLogo.WIDTH,
      "height": BlockLogo.HEIGHT,
      "alt": BlockLogo.ALT_TEXT
    },
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "register_type",
      "check": "modbus_register_type",
      "align": "RIGHT"
    },
    {
      "type": "input_value",
      "name": "register_address",
      "check": "modbus_register_address",
      "align": "RIGHT"
    },
    {
      "type": "input_value",
      "name": "register_value",
      "check": "Number",
      "align": "RIGHT"
    }
  ],
  "inputsInline": false,
  "previousStatement": null,
  "nextStatement": null,
  "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "modbus_register_address",
  "message0": "%1 Modbus register address %2",
  "args0": [
    {
      "type": "field_image",
      "src": BlockLogo.IMAGE,
      "width": BlockLogo.WIDTH,
      "height": BlockLogo.HEIGHT,
      "alt": BlockLogo.ALT_TEXT
    },
    {
      "type": "field_number",
      "name": "address",
      "value": 0,
      "min": 0,
      "max": 65535,
      "precision": 1
    }
  ],
  "output": "modbus_register_address",
  "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "conversion_scale_value",
  "message0": "%1 Conversion Scale Value: %2 Input Value %3 Input Min %4 Input Max %5 Output Min %6 Output Max %7",
  "args0": [
    {
      "type": "field_image",
      "src": BlockLogo.IMAGE,
      "width": BlockLogo.WIDTH,
      "height": BlockLogo.HEIGHT,
      "alt": BlockLogo.ALT_TEXT
    },
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "input_value",
      "check": "Number",
      "align": "RIGHT"
    },
    {
      "type": "input_value",
      "name": "input_value_min",
      "check": "Number",
      "align": "RIGHT"
    },
    {
      "type": "input_value",
      "name": "input_value_max",
      "check": "Number",
      "align": "RIGHT"
    },
    {
      "type": "input_value",
      "name": "output_value_min",
      "check": "Number",
      "align": "RIGHT"
    },
    {
      "type": "input_value",
      "name": "output_value_max",
      "check": "Number",
      "align": "RIGHT"
    }
  ],
  "inputsInline": false,
  "output": "Number",
  "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "conn_mod_enable_fail_safe",
  "message0": "%1 Enable Fail Safe %2",
  "args0": [
    {
      "type": "field_image",
      "src": BlockLogo.IMAGE,
      "width": BlockLogo.WIDTH,
      "height": BlockLogo.HEIGHT,
      "alt": BlockLogo.ALT_TEXT
    },
    {
      "type": "input_value",
      "name": "fail_safe_enable",
      "check": "Boolean",
      "align": "RIGHT"
    }
  ],
  "inputsInline": false,
  "previousStatement": null,
  "nextStatement": null,
  "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "conn_mod_configure_custom_bacnet_ai_object",
  "message0": "Configure Custom BACnet Analog Input Object %1 Instance %2 Name %3 Units %4",
  "args0": [
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "instance",
      "check": "bacnet_object_instance",
      "align": "RIGHT"
    },
    {
      "type": "input_value",
      "name": "name",
      "check": "String",
      "align": "RIGHT"
    },
    {
      "type": "input_value",
      "name": "units",
      "check": "Number",
      "align": "RIGHT"
    }
  ],
  "inputsInline": true,
  "previousStatement": null,
  "nextStatement": null,
  "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "conn_mod_write_custom_bacnet_ai_object",
  "message0": "Write BACnet Analog Input Object Value %1 Instance %2 Value %3",
  "args0": [
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "instance",
      "check": "bacnet_object_instance",
      "align": "RIGHT"
    },
    {
      "type": "input_value",
      "name": "value",
      "check": "Number",
      "align": "RIGHT"
    }
  ],
  "inputsInline": true,
  "previousStatement": null,
  "nextStatement": null,
  "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "conn_mod_write_custom_bacnet_av_object",
  "message0": "Write BACnet Analog Value Object Value %1 Instance %2 Value %3",
  "args0": [
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "instance",
      "check": "bacnet_object_instance",
      "align": "RIGHT"
    },
    {
      "type": "input_value",
      "name": "value",
      "check": "Number",
      "align": "RIGHT"
    }
  ],
  "inputsInline": true,
  "previousStatement": null,
  "nextStatement": null,
  "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "conn_mod_read_custom_bacnet_av_object",
  "message0": "Read BACnet Analog Value Object Value %1 Instance %2",
  "args0": [
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "instance",
      "check": "bacnet_object_instance",
      "align": "RIGHT"
    }
  ],
  "inputsInline": true,
  "output": "Number",
  "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "conn_mod_configure_custom_bacnet_av_object",
  "message0": "Configure Custom BACnet Analog Value Object %1 Instance %2 Name %3 Units %4 Min Val %5 Max Val %6",
  "args0": [
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "instance",
      "check": "bacnet_object_instance",
      "align": "RIGHT"
    },
    {
      "type": "input_value",
      "name": "name",
      "check": "String",
      "align": "RIGHT"
    },
    {
      "type": "input_value",
      "name": "units",
      "check": "Number",
      "align": "RIGHT"
    },
    {
      "type": "input_value",
      "name": "min_val",
      "check": "Number",
      "align": "RIGHT"
    },
    {
      "type": "input_value",
      "name": "max_val",
      "check": "Number",
      "align": "RIGHT"
    }
  ],
  "inputsInline": true,
  "previousStatement": null,
  "nextStatement": null,
  "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "conn_mod_configure_custom_bacnet_bv_object",
  "message0": "Configure Custom BACnet Binary Value Object %1 Instance %2 Name %3 Active Text %4 Inactive Text %5",
  "args0": [
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "instance",
      "check": "bacnet_object_instance",
      "align": "RIGHT"
    },
    {
      "type": "input_value",
      "name": "name",
      "check": "String",
      "align": "RIGHT"
    },
    {
      "type": "input_value",
      "name": "active_text",
      "check": "String",
      "align": "RIGHT"
    },
    {
      "type": "input_value",
      "name": "inactive_text",
      "check": "String",
      "align": "RIGHT"
    }
  ],
  "inputsInline": true,
  "previousStatement": null,
  "nextStatement": null,
  "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "conn_mod_configure_custom_bacnet_bi_object",
  "message0": "Configure Custom BACnet Binary Input Object %1 Instance %2 Name %3 Active Text %4 Inactive Text %5",
  "args0": [
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "instance",
      "check": "bacnet_object_instance",
      "align": "RIGHT"
    },
    {
      "type": "input_value",
      "name": "name",
      "check": "String",
      "align": "RIGHT"
    },
    {
      "type": "input_value",
      "name": "active_text",
      "check": "String",
      "align": "RIGHT"
    },
    {
      "type": "input_value",
      "name": "inactive_text",
      "check": "String",
      "align": "RIGHT"
    }
  ],
  "inputsInline": true,
  "previousStatement": null,
  "nextStatement": null,
  "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "conn_mod_write_custom_bacnet_bi_object",
  "message0": "Write BACnet Binary Input Object Value %1 Instance %2 Value %3",
  "args0": [
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "instance",
      "check": "bacnet_object_instance",
      "align": "RIGHT"
    },
    {
      "type": "input_value",
      "name": "value",
      "check": "Boolean",
      "align": "RIGHT"
    }
  ],
  "inputsInline": true,
  "previousStatement": null,
  "nextStatement": null,
  "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "conn_mod_write_custom_bacnet_bv_object",
  "message0": "Write BACnet Binary Value Object Value %1 Instance %2 Value %3",
  "args0": [
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "instance",
      "check": "bacnet_object_instance",
      "align": "RIGHT"
    },
    {
      "type": "input_value",
      "name": "value",
      "check": "Boolean",
      "align": "RIGHT"
    }
  ],
  "inputsInline": true,
  "previousStatement": null,
  "nextStatement": null,
  "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "conn_mod_read_custom_bacnet_bv_object",
  "message0": "Read BACnet Binary Value Object Value %1 Instance %2",
  "args0": [
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "instance",
      "check": "bacnet_object_instance",
      "align": "RIGHT"
    }
  ],
  "inputsInline": true,
  "output": "Boolean",
  "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "bacnet_object_instance",
  "message0": "BACnet Object Instance %1",
  "args0": [
    {
      "type": "field_number",
      "name": "instance",
      "value": 0,
      "min": 0,
      "max": 999,
      "precision": 1
    }
  ],
  "output": "bacnet_object_instance",
  "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "conn_mod_configure_custom_bacnet_msi_object",
  "message0": "Configure Custom BACnet Multistate Input Object %1 Instance %2 Name %3 State Text %4",
  "args0": [
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "instance",
      "check": "bacnet_object_instance",
      "align": "RIGHT"
    },
    {
      "type": "input_value",
      "name": "name",
      "check": "String",
      "align": "RIGHT"
    },
    {
      "type": "input_value",
      "name": "state_text",
      "check": "Array",
      "align": "RIGHT"
    }
  ],
  "inputsInline": true,
  "previousStatement": null,
  "nextStatement": null,
 "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "bacnet_units",
  "message0": "BACnet Units %1",
  "args0": [
    {
      "type": "field_dropdown",
      "name": "unit",
      "options": [
        [
          "square metre",
          "0"
        ],
        [
          "square foot",
          "1"
        ],
        [
          "milliampere",
          "2"
        ],
        [
          "ampere",
          "3"
        ],
        [
          "ohm",
          "4"
        ],
        [
          "volt",
          "5"
        ],
        [
          "kilivolt",
          "6"
        ],
        [
          "megavolt",
          "7"
        ],
        [
          "volt - ampere",
          "8"
        ],
        [
          "kilovolt - ampere",
          "9"
        ],
        [
          "megavolt - ampere",
          "10"
        ],
        [
          "volt-amperes-reactive",
          "11"
        ],
        [
          "kilovolt ampere (reactive)",
          "12"
        ],
        [
          "megavolt-amperes-reactive",
          "13"
        ],
        [
          "degrees-phase",
          "14"
        ],
        [
          "power-factor",
          "15"
        ],
        [
          "joule",
          "16"
        ],
        [
          "kilojoule",
          "17"
        ],
        [
          "watt hour",
          "18"
        ],
        [
          "kilowatt hour",
          "19"
        ],
        [
          "British thermal unit (mean)",
          "20"
        ],
        [
          "therm (U.S.)",
          "21"
        ],
        [
          "ton (US) per hour",
          "22"
        ],
        [
          "joules-per-kilogram-dry-air",
          "23"
        ],
        [
          "btus-per-pound-dry-air",
          "24"
        ],
        [
          "cycles-per-hour",
          "25"
        ],
        [
          "cycles-per-minute",
          "26"
        ],
        [
          "hertz",
          "27"
        ],
        [
          "grams-of-water-per-kilogram-dry-air",
          "28"
        ],
        [
          "percent-relative-humidity",
          "29"
        ],
        [
          "millimetre",
          "30"
        ],
        [
          "metre",
          "31"
        ],
        [
          "inch",
          "32"
        ],
        [
          "foot",
          "33"
        ],
        [
          "watts-per-square-foot",
          "34"
        ],
        [
          "watts-per-square-meter",
          "35"
        ],
        [
          "lumen",
          "36"
        ],
        [
          "lux",
          "37"
        ],
        [
          "footcandle",
          "38"
        ],
        [
          "kilogram",
          "39"
        ],
        [
          "pounds-mass",
          "40"
        ],
        [
          "tons",
          "41"
        ],
        [
          "kilogram per second",
          "42"
        ],
        [
          "kilogram per minute",
          "43"
        ],
        [
          "kilogram per hour",
          "44"
        ],
        [
          "pounds-mass-per-minute",
          "45"
        ],
        [
          "pounds-mass-per-hour",
          "46"
        ],
        [
          "watt",
          "47"
        ],
        [
          "kilowatt",
          "48"
        ],
        [
          "megawatt",
          "49"
        ],
        [
          "British thermal unit (thermochemical) per hour",
          "50"
        ],
        [
          "horsepower",
          "51"
        ],
        [
          "tons-refrigeration",
          "52"
        ],
        [
          "pascal",
          "53"
        ],
        [
          "kilopascal",
          "54"
        ],
        [
          "bar [unit of pressure]",
          "55"
        ],
        [
          "pound-force per square inch",
          "56"
        ],
        [
          "centimeters-of-water",
          "57"
        ],
        [
          "inch of water",
          "58"
        ],
        [
          "millimeters-of-mercury",
          "59"
        ],
        [
          "centimetre of mercury",
          "60"
        ],
        [
          "inch of mercury",
          "61"
        ],
        [
          "degree Celsius",
          "62"
        ],
        [
          "kelvin",
          "63"
        ],
        [
          "degree Fahrenheit",
          "64"
        ],
        [
          "degree-days-Celsius",
          "65"
        ],
        [
          "degree-days-Fahrenheit",
          "66"
        ],
        [
          "years",
          "67"
        ],
        [
          "month",
          "68"
        ],
        [
          "week",
          "69"
        ]
      ]
    }
  ],
  "output": "Number",
  "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "conn_mod_configure_custom_bacnet_msv_object",
  "message0": "Configure Custom BACnet Multistate Value Object %1 Instance %2 Name %3 State Text %4",
  "args0": [
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "instance",
      "check": "bacnet_object_instance",
      "align": "RIGHT"
    },
    {
      "type": "input_value",
      "name": "name",
      "check": "String",
      "align": "RIGHT"
    },
    {
      "type": "input_value",
      "name": "state_text",
      "check": "Array",
      "align": "RIGHT"
    }
  ],
  "inputsInline": true,
  "previousStatement": null,
  "nextStatement": null,
 "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "conn_mod_write_custom_bacnet_msi_object",
  "message0": "Write BACnet Multistate Input Object Value %1 Instance %2 Value %3",
  "args0": [
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "instance",
      "check": "bacnet_object_instance",
      "align": "RIGHT"
    },
    {
      "type": "input_value",
      "name": "value",
      "check": "Number",
      "align": "RIGHT"
    }
  ],
  "inputsInline": true,
  "previousStatement": null,
  "nextStatement": null,
 "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "conn_mod_write_custom_bacnet_msv_object",
  "message0": "Write BACnet Multistate Value Object Value %1 Instance %2 Value %3",
  "args0": [
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "instance",
      "check": "bacnet_object_instance",
      "align": "RIGHT"
    },
    {
      "type": "input_value",
      "name": "value",
      "check": "Number",
      "align": "RIGHT"
    }
  ],
  "inputsInline": true,
  "previousStatement": null,
  "nextStatement": null,
 "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "conn_mod_read_custom_bacnet_msv_object",
  "message0": "Read BACnet Multistate Value Object Value %1 Instance %2",
  "args0": [
    {
      "type": "input_dummy"
    },
    {
      "type": "input_value",
      "name": "instance",
      "check": "bacnet_object_instance",
      "align": "RIGHT"
    }
  ],
  "inputsInline": true,
  "output": "Number",
 "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "get_device_time",
  "message0": "Get Device Time",
  "inputsInline": true,
  "output": null,
  "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "set_device_time",
  "message0": "Set Device Time %1 HH %2 %3 MM %4 %5 DAY %6",
  "args0": [
    {
      "type": "input_dummy"
    },
    {
      "type": "field_number",
      "name": "hours",
      "value": 0,
      "min": 0,
      "max": 23,
      "precision": 1
    },
    {
      "type": "input_dummy"
    },
    {
      "type": "field_number",
      "name": "minutes",
      "value": 0,
      "min": 0,
      "max": 59,
      "precision": 1
    },
    {
      "type": "input_dummy"
    },
    {
      "type": "field_dropdown",
      "name": "day",
      "options": [
        [
          "MONDAY",
          "1"
        ],
        [
          "TUESDAY",
          "2"
        ],
        [
          "WEDNESDAY",
          "3"
        ],
        [
          "THURSDAY",
          "4"
        ],
        [
          "FRIDAY",
          "5"
        ],
        [
          "SATURDAY",
          "6"
        ],
        [
          "SUNDAY",
          "7"
        ]
      ]
    }
  ],
  "inputsInline": true,
  "previousStatement": null,
  "nextStatement": null,
  "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
},
{
  "type": "sequence_time",
  "message0": "Sequence Time %1 HH %2 %3 MM %4 %5 DAY %6",
  "args0": [
    {
      "type": "input_dummy"
    },
    {
      "type": "field_number",
      "name": "hours",
      "value": 0,
      "min": 0,
      "max": 23,
      "precision": 1
    },
    {
      "type": "input_dummy"
    },
    {
      "type": "field_number",
      "name": "minutes",
      "value": 0,
      "min": 0,
      "max": 59,
      "precision": 1
    },
    {
      "type": "input_dummy"
    },
    {
      "type": "field_dropdown",
      "name": "day",
      "options": [
        [
          "MONDAY",
          "1"
        ],
        [
          "TUESDAY",
          "2"
        ],
        [
          "WEDNESDAY",
          "3"
        ],
        [
          "THURSDAY",
          "4"
        ],
        [
          "FRIDAY",
          "5"
        ],
        [
          "SATURDAY",
          "6"
        ],
        [
          "SUNDAY",
          "7"
        ]
      ]
    }
  ],
  "inputsInline": true,
  "output": null,
  "colour": BlockColors.VARIABLE_DYNAMIC_BLOCKS,
  "tooltip": "",
  "helpUrl": ""
}]


export class dynamicDeviceBlockBuilder {
  static functionNameValidator(name: string) {
    return name.replace(/^[^a-zA-Z_]+|[^a-zA-Z0-9_]/g, '_');
  }
  static sequencerFunctionblockInit(block: any) {
    Blockly.Blocks[block.type] = {
      init: function () {
        this.appendDummyInput()
        .appendField(this.getBlockImage(), 'blockImage')
        .appendField(new Blockly.FieldLabel(block.seqFunctionName), 'seqFunctionName')
        .appendField(new Blockly.FieldLabel(block.functionId), 'functionId')
        .appendField(new Blockly.FieldTextInput(block.xml), 'xml')
        .appendField(new Blockly.FieldTextInput(block.snippet), 'snippet')
        .appendField(new Blockly.FieldTextInput(block.why), 'why')
        .appendField(new Blockly.FieldTextInput(block.what), 'what')
        this.setColour(270);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setTooltip(`Why: ${block.why}, What: ${block.what}`);
        this.setHelpUrl('');
        this.updateBlockShape();
      },

      // Create an image field with specified source, width, and height
      getBlockImage() {
        const src = BlockLogo.IMAGE;
        const width = BlockLogo.WIDTH;
        const height = BlockLogo.HEIGHT;
        const alt = BlockLogo.ALT_TEXT
        return new Blockly.FieldImage(src, width, height, alt);
      },

      // Hiding the xml and snippet fields of the block.
      updateBlockShape: function() {
        this.getField('functionId').setVisible(false);
        this.getField('xml').setVisible(false);
        this.getField('snippet').setVisible(false);
        this.getField('why').setVisible(false);
        this.getField('what').setVisible(false);
      }
    }
    
    // Code generation
    python.pythonGenerator.forBlock[block.type] = function (block: any, generator: any) {
      var functionName = block.getFieldValue('seqFunctionName');
      var code = `${dynamicDeviceBlockBuilder.functionNameValidator(functionName)}()\n`;
      return code;
    };
  };

}