
import { BlockLogo } from 'src/app/shared/enums/SequenceKeys.enum';
import { BlockColors } from '../blockly-theme';

export const UNIT_CONVERSION_BLOCKS = [
  {
    "type": "temp_conversion",
    "message0": "%1 convert temperature from %2 to %3 %4 %5",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "field_dropdown",
        "name": "FROM_UNIT",
        "options": [
          [
            "fahrenheit",
            "FAHRENHEIT"
          ],
          [
            "celsius",
            "CELSIUS"
          ],
          [
            "kelvin",
            "KELVIN"
          ]
        ]
      },
      {
        "type": "field_dropdown",
        "name": "TO_UNIT",
        "options": [
          [
            "celsius",
            "CELSIUS"
          ],
          [
            "fahrenheit",
            "FAHRENHEIT"
          ],
          [
            "kelvin",
            "KELVIN"
          ]
        ]
      },
      {
        "type": "input_dummy"
      },
      {
        "type": "input_value",
        "name": "TEMP_INPUT",
        "check": "Number"
      }
    ],
    "output": null,
    "colour": BlockColors.UNIT_CONVERSION_BLOCKS,
    "tooltip": "Convert temperature from one unit to another",
    "helpUrl": "Documenation URL",
    'extensions': [
      'unit_selection_validation'
    ],
  },
  {
    "type": "angular_velocity_conversion",
    "message0": "%1 convert angular velocity from %2 to %3 %4 %5",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "field_dropdown",
        "name": "FROM_UNIT",
        "options": [
          [
            "rad/sec",
            "RAD_PER_SEC"
          ],
          [
            "rpm",
            "RPM"
          ]
        ]
      },
      {
        "type": "field_dropdown",
        "name": "TO_UNIT",
        "options": [
          [
            "rpm",
            "RPM"
          ],
          [
            "rad/sec",
            "RAD_PER_SEC"
          ]
        ]
      },
      {
        "type": "input_dummy"
      },
      {
        "type": "input_value",
        "name": "ANG_VELOCITY_INPUT",
        "check": "Number"
      }
    ],
    "output": null,
    "colour": BlockColors.UNIT_CONVERSION_BLOCKS,
    "tooltip": "Convert angular velocity from one unit to another",
    "helpUrl": "Documenation URL",
    'extensions': [
      'unit_selection_validation'
    ],
  },
  {
    "type": "velocity_conversion",
    "message0": "%1 convert velocity from %2 to %3 %4 %5",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "field_dropdown",
        "name": "FROM_UNIT",
        "options": [
          [
            "m/sec",
            "METER_PER_SEC"
          ],
          [
            "m/hr",
            "METER_PER_HR"
          ]
        ]
      },
      {
        "type": "field_dropdown",
        "name": "TO_UNIT",
        "options": [
          [
            "m/hr",
            "METER_PER_HR"
          ],
          [
            "m/sec",
            "METER_PER_SEC"
          ]
        ]
      },
      {
        "type": "input_dummy"
      },
      {
        "type": "input_value",
        "name": "VELOCITY_INPUT",
        "check": "Number"
      }
    ],
    "output": null,
    "colour": BlockColors.UNIT_CONVERSION_BLOCKS,
    "tooltip": "Convert velocity from one unit to another",
    "helpUrl": "Documenation URL",
    'extensions': [
      'unit_selection_validation'
    ],
  },
  {
    "type": "area_conversion",
    "message0": "%1 convert area from %2 to %3 %4 %5",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "field_dropdown",
        "name": "FROM_UNIT",
        "options": [
          [
            "m2",
            "METER_SQUARE"
          ],
          [
            "ft2",
            "FEET_SQUARE"
          ]
        ]
      },
      {
        "type": "field_dropdown",
        "name": "TO_UNIT",
        "options": [
          [
            "ft2",
            "FEET_SQUARE"
          ],
          [
            "m2",
            "METER_SQUARE"
          ]
        ]
      },
      {
        "type": "input_dummy"
      },
      {
        "type": "input_value",
        "name": "AREA_INPUT",
        "check": "Number"
      }
    ],
    "output": null,
    "colour": BlockColors.UNIT_CONVERSION_BLOCKS,
    "tooltip": "Convert area from one unit to another",
    "helpUrl": "Documenation URL",
    'extensions': [
      'unit_selection_validation'
    ],
  },
  {
    "type": "volume_conversion",
    "message0": "%1 convert volume from %2 to %3 %4 %5",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "field_dropdown",
        "name": "FROM_UNIT",
        "options": [
          [
            "m3",
            "CUBIC_METER"
          ],
          [
            "liter",
            "LITER"
          ],
          [
            "gallons",
            "GALLONS"
          ]
        ]
      },
      {
        "type": "field_dropdown",
        "name": "TO_UNIT",
        "options": [
          [
            "liter",
            "LITER"
          ],
          [
            "gallons",
            "GALLONS"
          ],
          [
            "m3",
            "CUBIC_METER"
          ]
        ]
      },
      {
        "type": "input_dummy"
      },
      {
        "type": "input_value",
        "name": "VOLUME_INPUT",
        "check": "Number"
      }
    ],
    "output": null,
    "colour": BlockColors.UNIT_CONVERSION_BLOCKS,
    "tooltip": "Convert volume from one unit to another",
    "helpUrl": "Documenation URL",
    'extensions': [
      'unit_selection_validation'
    ],
  },
  {
    "type": "enthalpy_conversion",
    "message0": "%1 convert enthalpy from %2 to %3 %4 %5",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "field_dropdown",
        "name": "FROM_UNIT",
        "options": [
          [
            "kj/kg",
            "kJOULE_PER_KG"
          ],
          [
            "btu/lb",
            "BTU_PER_LB"
          ]
        ]
      },
      {
        "type": "field_dropdown",
        "name": "TO_UNIT",
        "options": [
          [
            "btu/lb",
            "BTU_PER_LB"
          ],
          [
            "kj/kg",
            "kJOULE_PER_KG"
          ]
        ]
      },
      {
        "type": "input_dummy"
      },
      {
        "type": "input_value",
        "name": "ENTHALPY_INPUT",
        "check": "Number"
      }
    ],
    "output": null,
    "colour": BlockColors.UNIT_CONVERSION_BLOCKS,
    "tooltip": "Convert enthalpy from one unit to another",
    "helpUrl": "Documenation URL",
    'extensions': [
      'unit_selection_validation'
    ],
  },
  {
    "type": "power_conversion",
    "message0": "%1 convert power from %2 to %3 %4 %5",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "field_dropdown",
        "name": "FROM_UNIT",
        "options": [
          [
            "watt",
            "WATT"
          ],
          [
            "kj/hr",
            "kJOULE_PER_HR"
          ]
        ]
      },
      {
        "type": "field_dropdown",
        "name": "TO_UNIT",
        "options": [
          [
            "kj/hr",
            "kJOULE_PER_HR"
          ],
          [
            "watt",
            "WATT"
          ]
        ]
      },
      {
        "type": "input_dummy"
      },
      {
        "type": "input_value",
        "name": "POWER_INPUT",
        "check": "Number"
      }
    ],
    "output": null,
    "colour": BlockColors.UNIT_CONVERSION_BLOCKS,
    "tooltip": "Convert power from one unit to another",
    "helpUrl": "Documenation URL",
    'extensions': [
      'unit_selection_validation'
    ],
  },
  {
    "type": "force_conversion",
    "message0": "%1 convert force from %2 to %3 %4 %5",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "field_dropdown",
        "name": "FROM_UNIT",
        "options": [
          [
            "n",
            "NEWTON"
          ],
          [
            "pounds",
            "POUNDS"
          ]
        ]
      },
      {
        "type": "field_dropdown",
        "name": "TO_UNIT",
        "options": [
          [
            "pounds",
            "POUNDS"
          ],
          [
            "n",
            "NEWTON"
          ],
        ]
      },
      {
        "type": "input_dummy"
      },
      {
        "type": "input_value",
        "name": "FORCE_INPUT",
        "check": "Number"
      }
    ],
    "output": null,
    "colour": BlockColors.UNIT_CONVERSION_BLOCKS,
    "tooltip": "Convert force from one unit to another",
    "helpUrl": "Documenation URL",
    'extensions': [
      'unit_selection_validation'
    ],
  },
  {
    "type": "energy_conversion",
    "message0": "%1 convert energy from %2 to %3 %4 %5",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "field_dropdown",
        "name": "FROM_UNIT",
        "options": [
          [
            "joule",
            "JOULE"
          ],
          [
            "kwh",
            "KWH"
          ],
          [
            "GJ",
            "GJ"
          ],
          [
            "watt hour",
            "WATT_HOUR"
          ],
          [
            "btu",
            "BTU"
          ],
          [
            "Kbtu",
            "KBTU"
          ],
          [
            "tonrefh",
            "TONREFH"
          ]
        ]
      },
      {
        "type": "field_dropdown",
        "name": "TO_UNIT",
        "options": [
          [
            "kwh",
            "KWH"
          ],
          [
            "joule",
            "JOULE"
          ],
          [
            "GJ",
            "GJ"
          ],
          [
            "watt hour",
            "WATT_HOUR"
          ],
          [
            "btu",
            "BTU"
          ],
          [
            "Kbtu",
            "KBTU"
          ],
          [
            "tonrefh",
            "TONREFH"
          ]
        ]
      },
      {
        "type": "input_dummy"
      },
      {
        "type": "input_value",
        "name": "ENERGY_INPUT",
        "check": "Number"
      }
    ],
    "output": null,
    "colour": BlockColors.UNIT_CONVERSION_BLOCKS,
    "tooltip": "Convert energy from one unit to another",
    "helpUrl": "Documenation URL",
    'extensions': [
      'unit_selection_validation'
    ],
  },
  {
    "type": "flow_conversion",
    "message0": "%1 convert flow from %2 to %3 %4 %5",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "field_dropdown",
        "name": "FROM_UNIT",
        "options": [
          [
            "m3/hr",
            "CUBIC_METER_PER_HR"
          ],
          [
            "ft3/hr",
            "FEET_CUBIC_PER_HR"
          ],
          [
            "l/s",
            "LITER_PER_SEC"
          ],
          [
            "l/min",
            "LITER_PER_MINUTE"
          ],
          [
            "m3/s",
            "CUBIC_METER_PER_SEC"
          ],
          [
            "gpm",
            "GALLON_PER_MINUTE"
          ],
          [
            "cfm",
            "CUBIC_FEET_PER_MINUTE"
          ]
        ]
      },
      {
        "type": "field_dropdown",
        "name": "TO_UNIT",
        "options": [
          [
            "ft3/hr",
            "FEET_CUBIC_PER_HR"
          ],
          [
            "l/s",
            "LITER_PER_SEC"
          ],
          [
            "l/min",
            "LITER_PER_MINUTE"
          ],
          [
            "m3/s",
            "CUBIC_METER_PER_SEC"
          ],
          [
            "gpm",
            "GALLON_PER_MINUTE"
          ],
          [
            "cfm",
            "CUBIC_FEET_PER_MINUTE"
          ],
          [
            "m3/hr",
            "CUBIC_METER_PER_HR"
          ]
        ]
      },
      {
        "type": "input_dummy"
      },
      {
        "type": "input_value",
        "name": "FLOW_INPUT",
        "check": "Number"
      }
    ],
    "output": null,
    "colour": BlockColors.UNIT_CONVERSION_BLOCKS,
    "tooltip": "Convert flow from one unit to another",
    "helpUrl": "Documenation URL",
    'extensions': [
      'unit_selection_validation'
    ],
  },
  {
    "type": "pressure_conversion",
    "message0": "%1 convert pressure from %2 to %3 %4 %5",
    "args0": [
      {
        "type": "field_image",
        "src": BlockLogo.IMAGE,
        "width": BlockLogo.WIDTH,
        "height": BlockLogo.HEIGHT,
        "alt": BlockLogo.ALT_TEXT
      },
      {
        "type": "field_dropdown",
        "name": "FROM_UNIT",
        "options": [
          [
            "psi",
            "POUND_PER_SQUARE_INCH"
          ],
          [
            "bar",
            "BAR"
          ],
          [
            "pascal",
            "PASCAL"
          ],
          [
            "kPa",
            "KPA"
          ],
          [
            "inH2O",
            "inH2O"
          ],
          [
            "inHg",
            "inHg"
          ],
          [
            "mmH2O",
            "mmH2O"
          ],
          [
            "cmH2O",
            "cmH2O"
          ],
        ]
      },
      {
        "type": "field_dropdown",
        "name": "TO_UNIT",
        "options": [
          [
            "bar",
            "BAR"
          ],
          [
            "psi",
            "POUND_PER_SQUARE_INCH"
          ],
          [
            "pascal",
            "PASCAL"
          ],
          [
            "kPa",
            "KPA"
          ],
          [
            "inH2O",
            "inH2O"
          ],
          [
            "inHg",
            "inHg"
          ],
          [
            "mmH2O",
            "mmH2O"
          ],
          [
            "cmH2O",
            "cmH2O"
          ],
        ]
      },
      {
        "type": "input_dummy"
      },
      {
        "type": "input_value",
        "name": "PRESSURE_INPUT",
        "check": "Number"
      }
    ],
    "output": null,
    "colour": BlockColors.UNIT_CONVERSION_BLOCKS,
    "tooltip": "Convert pressure from one unit to another",
    "helpUrl": "Documenation URL",
    'extensions': [
      'unit_selection_validation'
    ],
  }
];
